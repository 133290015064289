@import "../settings";

.relationship {
  padding: $global-padding * 3 0 ;

  @include breakpoint(medium) {
    padding: $global-padding * 4 0;
  }

  @include breakpoint(large) {
    padding: $global-padding * 6 0;
  }

  &__background-image {
    background-image: url('../images/confetti-coloured.svg');
    background-repeat: repeat;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @media screen and (prefers-reduced-motion: reduce) {
      transform: inherit !important;
    }
    @include breakpoint(small only) {
      transform: inherit !important;
    }
  }

  .mon-row {
    position: relative;
    z-index: 1;
  }

  // Center the view all button and push it down from the cards.
  &__view-all {
    margin-top: $global-margin * 3;
    text-align: center;

    .button {
      @include button(button--outline-primary);
    }
  }

  &__heading-row {
    @include mon-cols(7);

    color: $secondary-color;
    text-align: center;
  }

  &__heading {
    @extend .block--small !optional;
  }

  .card {
    &__category {
      color: $brand-green;
      font-size: rem-calc(26);
      font-weight: $global-weight-medium;
    }
  }
}

// -------------------------
// Layouts
// -------------------------
// Default 100% wide grid when 1 item is selected.
.relationship__grid,
.relationship__grid--1 {
  @include mon-grid();
}

// Add some padding to the single selection content so the image stretches.
.relationship__grid--1 .relationship__content {
  padding: $global-padding * 3 $global-padding * 2;
}

// 2 column grid when 2 items are selected.
.relationship__grid--2 {
  @include breakpoint(medium) {
    @include mon-grid(2);

    & > * {
      flex-basis: calc(50% - #{$global-margin});
    }
  }
}

// 3 column grid when 3 or more items are selected.
.relationship__grid--3 {
  @include breakpoint(medium) {
    @include mon-grid(3);

    & > * {
      flex-basis: calc(33.33% - #{$global-margin});
    }
  }
}

// -------------------------
// Card overrides. Most of these styles are using _cards.scss.
// -------------------------

// 3 or more posts card layout, stack the image above the text.
.relationship__grid--3 .card {
  display: block;
}
